<template>
  <a-spin :spinning="spinning">
    <!-- <page-header title="项目协议管理"></page-header> -->
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :actions-type="actionsType"
      :columns="columns"
      :scroll="{ x: 0 }"
      :hideRowSelection="true"
      :current-page="pageNumber"
      :is-full="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <!-- <a slot="totalCount" slot-scope="text">{{ text }}</a> -->
      <a-row :gutter="8">
        <a-col :span="4">
            <a-input
              v-model="SearchDto.ProjectName"
              placeholder="请选择项目"
              @click="selectProjectModal"
            >
            </a-input>
            <a-input v-model="SearchDto.ProjectId" type="hidden">
            </a-input>
          </a-col>
        <a-col class="gutter-row" :span="4">
          <a-input
            v-model.trim="SearchDto.agreementTemplateName"
            :placeholder="l('协议名称')"
            allowClear
          />
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-select
            placeholder="选择类型"
            style="width: 100%"
            @change="selectChange"
            v-model="SearchDto.isStandard"
          >
            <a-select-option
              :value="it.value"
              v-for="(it, i) in isStandardList"
              :key="i"
            >
              {{ it.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-select
            placeholder="选择分组"
            style="width: 100%"
            @change="selectChange1"
            v-model="SearchDto.agreementGroupType"
          >
            <a-select-option
              :value="it.value"
              v-for="(it, i) in GroupList"
              :key="i"
            >
              {{ it.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-button
            type="primary"
            @click="search"
            v-if="isGranted('Pages.UnnaturalProjectAgreementPermissions.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import EleTable from "@/components/ele-table";
import TempleteList from "./project-agreement/templete-list";
import moment from "moment";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import SelectPageList from "@/components/linggong/select-page-list";

let vm = this;

export default {
  mixins: [AppComponentBase],
  name: "agreementsignmanage",
  components: { EleTable },

  data() {
    return {
      spinning: false,
      SearchDto: {
        SkipCount: 0,
        MaxResultCount: 50,
        Sorting: "",
        agreementTemplateName: "",
        isStandard: undefined,
        agreementGroupType: undefined,
        ProjectIds:"",
        ProjectName:"",
      },
      name: "",
      projectData: [],
      selectedRowKeys: [],
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      pagerange: [1, 1],
      pageSizeOptions: ["10", "20", "30", "40"],
      tableData: [],
      columns: [],
      child: "",
      isStandardList: [
        { value: true, label: "标准版" },
        { value: false, label: "非标准版" },
      ],
      GroupList: [
        { label: "未分组", value: 0 },
        { label: "合同协议", value: 1 },
        { label: "证明文件", value: 2 },
      ],
      commonService: null,
      selectProjectList: "",
    };
  },
  created() {
    // console.log(this);
    // this.initChild();
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    // this.getProject();
    this.getData();
    this.initActionsType();
  },

  methods: {
    selectProjectModal() {
      ModalHelper.create(
        SelectPageList,
        {
          selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/UnnaturalPersonProject/GetSelectPaged", // 数据接口URL
          searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        console.log(res);
        const { success, data } = res;
        if (success) {
          //   message.success("succed");
          //   console.log(data);
          let projectIds = "";
          let projectNames = "";
          for (let i = 0; i < data.length; i += 1) {
            const item = data[i];
            if (i === data.length - 1) {
              projectIds += item.id;
              projectNames += item.name;
              continue;
            }
            projectIds += item.id + ",";
            projectNames += item.name + ",";
          }

          this.SearchDto.ProjectIds = projectIds; //data.id;
          this.SearchDto.ProjectName = projectNames; //data.name;

          this.getData();
          // this.getTaskByProjectId(0, data.id);
        }
      });
    },
    initColumns() {
      var vm = this;
      this.columns = [
        {
          title: this.l("项目名称"),
          dataIndex: "projectName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "projectName" },
        },
        {
          title: this.l("协议名称"),
          dataIndex: "agreementTemplateName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "agreementTemplateName" },
        },
        {
          title: this.l("类型"),
          dataIndex: "isStandard",
          sorter: true,
          align: "center",
          // width: "150",
          customRender: function (text, record, index) {
            if (record.isStandard) {
              return "标准版";
            } else {
              return "非标准版";
            }
          },
          scopedSlots: { customRender: "isStandard" },
        },
        {
          title: this.l("协议分组"),
          dataIndex: "agreementGroupTypeName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "agreementGroupTypeName" },
        },
        {
          title: this.l("状态"),
          dataIndex: "status",
          sorter: true,
          align: "center",
          // width: "150",
          scopedSlots: { customRender: "status" },
          customRender: function (text, record, index) {
            if (text == "Enabled") {
              return "启用";
            } else {
              return "禁用";
            }
          },
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ];
    },
    initChild(text) {
      var child = this.$createElement("a", {
        domProps: {
          innerHTML: text,
        },
        on: {
          click: function () {
            this.signClick(text);
          },
        },
      });
      this.child = child;
      console.log(child);      
    },
    _projectAgreementModal(item) {
      ModalHelper.create(
        TempleteList,
        {
          id: item.id,
          agreementTemplateId: item.agreementTemplateId,
          projectId: item.projectId,
        },
        {
          isChange: true,
          width: "1000px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    selectChange(value) {
      this.SearchDto.isStandard = value;
    },
    selectChange1(value) {
      this.SearchDto.agreementGroupType = value;
    },
    search() {
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    getData() {
      this.getPaged();
      // this.tableData = [
      //   {
      //     name: "灵活经营服务协议",
      //     totalCount: 120,
      //     sign: 100,
      //     nosign: 20,
      //   },
      //   {
      //     name: "自由者从业协议",
      //     totalCount: 120,
      //     sign: 100,
      //     nosign: 20,
      //   },
      // ];
    },
    async _changeStatus(id) {
      this.spinning = true;
      try {
        const res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/StatusChange",
          params: { id },
        });
        this.getData();
        this.$notification["success"]({
          message: this.l("更新状态成功"),
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async getPaged() {
      this.spinning = true;
      console.log(this.SearchDto)
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/GetPaged",
          params: {
            isStandard: this.SearchDto.isStandard,
            agreementGroupType: this.SearchDto.agreementGroupType,
            agreementTemplateName: this.SearchDto.agreementTemplateName,
            sorting: this.SearchDto.Sorting,
            projectIds:this.SearchDto.ProjectIds,
            maxResultCount: this.SearchDto.MaxResultCount,
            skipCount: this.SearchDto.SkipCount,
          },
        });
        // console.log(res);
        this.tableData = res.items.map((item) => {
          return {
            ...item,
            isStandard: item.isStandard ? "标准版" : "非标准版",
            status: item.status === "Enabled" ? "启用" : "禁用",
          };
        });
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(2 / this.SearchDto.MaxResultCount);
        this.pagerange = [
          (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
          this.pageNumber * this.SearchDto.MaxResultCount,
        ];
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "sign-manage",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted(
                "Pages.UnnaturalProjectAgreementPermissions.SendSign"
              ),
              name: this.l("发起签署"),
              icon: "profile",
              fn: (data) => {
                _this._projectAgreementModal(data);
              },
            },
          ],
          delete: {
            granted: this.isGranted(
              "Pages.UnnaturalProjectAgreementPermissions.StopOrStart"
            ),
            name: this.l("禁/启用"),
            tipsDesc: "禁/启用人员?",
            fn: (data) => {
              _this._changeStatus(data.id);
            },
          },
        },
      };
      this.actionsType = obj;
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.SearchDto.Sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.SearchDto.MaxResultCount = pageSize;
      this.SearchDto.SkipCount = (page - 1) * pageSize;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    lookSign(item) {
      const { pdfTemplateUrl } = item;
      if (pdfTemplateUrl) {
        window.open(`${AppConsts.uploadBaseUrl}${pdfTemplateUrl}`);
      } else {
        this.$message.info("暂无协议");
      }
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.refreshGoFirstPage();
    },
    refreshGoFirstPage() {
      this.SearchDto.agreementTemplateName = "";
      this.SearchDto.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.SearchDto.isStandard = undefined;
      this.SearchDto.agreementGroupType = undefined;
      this.SearchDto.ProjectIds="",
      this.SearchDto.ProjectName="",
      this.SearchDto.sorting = "";
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // signClick(item) {
    //   //   this.message.success(text);
    //   this.lookSignPerson(item, 1);
    // },
    // noSignClick(item) {
    //   //   this.message.success("测试");
    //   this.lookSignPerson(item, 0);
    // },
    // lookSignPerson(item, signstatus) {
    //   ModalHelper.create(
    //     signperonlist,
    //     {
    //       signPerson: item,
    //       signstatus: signstatus,
    //     },
    //     {
    //       isChange: true,
    //       width: "1200px",
    //     }
    //   ).subscribe((res) => {
    //     if (res) {
    //       this.refreshGoFirstPage();
    //     }
    //   });
    // },
  },
};
</script>
<style scoped lang="less">
</style>